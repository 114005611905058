/* .login-parent{
    background-image: url('../../images/Login.png');
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
} */
.login-card-container{
    width: 50%;
    margin: 4rem  auto;
    /* background-color: rgb(39, 108, 168); */
    padding: 1rem;
}

.login-card-container .login-card{
    width: 90%;
    margin: auto;
    display: flex;
    background-color: #fff;
    justify-content: space-evenly;
    border-radius: 2rem;
    
}

.login-form-container{
    width: 40%;
    padding: 2.7rem;
    /* border: 2px solid green; */
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-right: none;
}
.login-card-container .login-card .login-form-container .login-form{
    display: flex;
    flex-direction: column;
}
.login-card-container .login-card  .login-image-container{
    width: 50%;
}
.login-card-container .login-card  .login-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.login-button{
    padding: 0.5rem 1rem ;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    /* background-color: #1ABC9C; */
    background-color: #0C5546;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}

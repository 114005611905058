.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}
.popup{
  position: fixed;
  z-index: 1;
  top: 5%;
  left:30%;
  width: 45%;
  background: #fff;
  border: none;
  border-radius: 1rem;
  overflow-y: auto;
  padding: 1rem;
  /* height: 80vh; */
}
.popup1,.popup2{
  display: none;
}
.popup textarea {
  /* width: 90%; */
  margin: auto;
  overflow: auto;
  padding: 1rem;
}
.popup .form-input{
 
  border: 0.5px solid rgb(233, 233, 233);
}
select{
  padding: 0.3rem;
  text-indent: 5px;
}


.labelForTemplate{
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 14px;
}
.popup-header{
  display: flex;
  /* background-color: #1390ae; */
  /* padding: 0.5rem; */
  margin: 0;
  justify-content: space-between;
  
}
.popup-header label{
  font-size: 16px;
  font-weight: bold;
  color: #0C5546;
}
.popup-body{
  margin-top: 1.5rem;
}
.popup-top-container{
  /* background-color: red; */
  width: 98%;
  margin: auto;
  /* display: flex;
  justify-content: space-between; */
}
.popup-form-fields{
  padding: 1rem;
}
.popup .input-container{
  padding: 0.3rem;
  margin-bottom: 0;
}
.popup .selectContainer{
  padding: 0.3rem;
  width: 100%;
}
.error-message{
  color: rgb(187, 3, 3);
}
.success-message{
  color: green;
}
.msg-background{
  padding: 0.3rem 1rem;
  background-color: rgb(184, 253, 184);
  border-radius: 5px;
}
.rate-container{
  display: flex;
}
.popup-btn, .popup-btn-sec{
  float: right;
}
.popup-btn-sec{
  background-color: white;
  border: 1px solid #1ABC9C;
  margin-right: 1rem;
  color: #1ABC9C;
}
.popup-body label{
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.defaulter-container{
  display: flex;
  /* justify-content: space-evenly; */
}
.defaulter-container .input-container{
 
  margin-right: 10%;
}
.defaulter-container .input-container .date{
  width: 15rem;
}
.defaulter-container .defaulter{
  width: 30%;
}
.form-container .input-container{
  margin-bottom: 0.5rem;
}
 .input-container label{
  font-size: 14px;
}
.select-gender{
  display: flex;
  flex-direction: column;
}
.success-msg{
  padding: 0.5rem;
  width:95%;
  background-color: #1ABC9C;
}
.heading{
  margin-top: -1.5rem;
}
.msg-hide{
  display: none;
}

.mobile-user-input{
  width: 50%;
}
.mb-usr-flx{
  display: flex;
  flex-direction: column;
}
.header-container{
  display: flex;
}
.bill-table{
  width: 100%;
  /* background-color: #0C5546; */
  border-collapse: collapse;
}
.bill-table tr td{
  border: 0.5px solid #0C5546;
}
.bill-table .header-data{
  color: rgb(12, 85, 70);
  padding: 0.5rem;
}
.main-bill-heading{
  text-align: center;
}
.long-msg{
  width: 100%;
  padding: 0.5rem 0;
  background-color: #1ABC9C;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.first{
  margin-top: 2rem;
}

.validation-msg{
    font-size: 13px;
    color: red;
}
.error-msg{
  background-color: rgb(255, 120, 120);
  color: black;
}
.long-btn{
  width: 100%;
}
.survey-Stage-msg{
  width: 80%;
  margin-bottom: 2rem;
  margin-left: 20%;
  border-radius: 0.2rem;
  background-color: rgb(12, 85, 70,0.5); 
}
.survey-Stage-error-msg{
  background-color: rgba(255, 72, 0, 0.5);
}
.disabled{
  background-color: lightgray;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  border: none;
  outline: none;
}

.two-btn{
  width: 45%;
}
.print-btn{
  margin-top: 2rem;
  color: white;
  font-weight: bold;
}
.half-width{
  justify-content: space-between;
}
.header{
  background-color: red;
  padding: 0;
  height: fit-content;
  text-align: start;
}
.close{
  height: auto;
  background-color: palegoldenrod;
}
.popup-header-container{
  background-color: red;
}

.popup-label{
  width: 50%;
}

.btn-close{
  height: 0;
  text-align: center;
  
}
.btn-close button{
  padding: 0.5rem 1rem;
  background-color: #0C5546;
  border: none;
  color: #fff;
  cursor: pointer;
}
.clear-btn{
  border:none;
  background-color: #fff;
  border-radius: 5px;
  border-left: none;
  cursor: pointer;
  margin-left: 0.5rem;
}
.header-container{
    display: flex;
    justify-content: space-between;
}

  thead .ant-table-cell {
  background-color: #0C5546 !important;
  border-right: none;
  color: white !important;
}
.btn{
    padding: 0.5rem 1rem ;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    /* background-color: #1ABC9C; */
    background-color: #0C5546;
    color: white;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.icon{
    margin-right: 0.7rem;
    font-size: 16px;
}
table, th, tr {
    border-bottom: 0.1px solid rgb(233, 233, 233) !important;
    border-radius: 0px !important;
  }
  thead th{
    border: none !important;
    outline: none!important;
    border-radius: 0px !important;
  }